<template>
  <div class="cont" v-loading="loading">
    <h2 class="part-title">个人作品上传</h2>
    <br />
    <el-form ref="form" :model="form" label-position="left" label-width="100px">
      <el-form-item label="作品名称：">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="作品分类：" v-if="isLook !== '2'">
        <el-select v-model="form.class_type" placeholder="请选择" multiple>
          <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容简介：">
        <el-input v-model="form.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="作品上传：" class="spe-item" v-if="isLook !== '2'">
        <upload-vedio @getlist="getlist"></upload-vedio>
      </el-form-item>

      <el-form-item label="路径：" v-if="isShowUrl == '1' || isLook == '2'">
        <el-input class="path" v-model="form.url"></el-input>
      </el-form-item>
      <el-form-item v-if="isLook !== '2'">
        <el-button type="danger" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import UploadVedio from './uploadVedio.vue'
export default {
  name: 'UploadWork',
  components: { UploadVedio },
  data() {
    return {
      form: {},
      isShowUrl: this.type,
      isLook: this.look,
      uploadUrl: 'work/update',
      options: [
        '学生与教育',
        '计算机与互联网',
        '职业与技能',
        '管理与运营',
        '金融与财经',
        '艺术与文学',
        '机械与工程',
        '社会与公益',
      ],
      loading: false,
    }
  },
  props: ['parForm', 'type', 'look'],
  created() {
    this.form = {}
    if (this.parForm) {
      this.form = this.parForm
    }
  },
  watch: {
    parForm: {
      handler(n) {
        this.form = n
      },
      deep: true,
    },
  },
  methods: {
    onSubmit() {
      this.loading = true
      let _this = this
      let param = JSON.parse(JSON.stringify(this.form))
      param.author = this.$store.state.wps_user.name
      param.class_type = JSON.stringify(param.class_type)
      let url
      if (this.type == '1') {
        url = 'work/update'
      } else {
        url = 'work'
      }
      _this.$http
        .post(process.env.VUE_APP_URL + url, param)
        .then(data => {
          this.loading = false
          if (data.data.code == 200) {
            _this.$emit('getlist', data.data.code)
            // _this.workList = data.data.data;
            _this.$message.success('上传成功!')
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
          this.loading = false
        })
    },
    getUloadUrl(url) {
      this.form.url = url
    },
    getlist(data) {
      this.form.url = data
    },
  },
}
</script>
<style lang="less" scoped>
.cont {
  .el-input,
  .el-select {
    width: 220px;
  }

  /deep/.el-button--danger {
    background: #e10133;
    color: #fff;
  }
}
.path /deep/ input {
  border: none;
  font-size: 9px;
  width: 350px;
}
</style>
