<template>
  <el-upload
    class="avatar-uploader"
    action="http://upload.qiniup.com/"
    :show-file-list="false"
    :before-upload="beforeAvatarUpload"
    :limit="1"
    :file-list="fileList"
    accept=".mp4"
  >
    <el-button size="small" type="danger">点击上传</el-button>
    <span v-if="percent > 0 && percent < 100">上传进度{{ percent }}%</span>
    <span v-if="percent === 100" class="uploadSuccess">上传完成</span>
    <div slot="tip" class="el-upload__tip">
      提示：上传附件格式为,MP4视频文件，大小不超过200M
    </div>
  </el-upload>
</template>
<script>
var qiniu = require('qiniu-js')
let _this
export default {
  name: 'Upload',
  data() {
    return {
      token: '',
      id: '',
      imageUrl: '',
      percent: 0,
      url: 'http://resource.wangpeishi.org.cn/',
      ext: '',
      CoverputExtra: {},
      config: {
        useCdnDomain: true,
      },
      fileList: [],
    }
  },
  created() {
    _this = this
    this.id = this.Storage.getStorage('wps_user').id
    this.$http
      .post(process.env.VUE_APP_URL + 'getQiNiuToken')
      .then(function(data) {
        _this.token = data.data.data
      })
      .catch(function() {
        _this.$message.error('网络错误,请刷新界面重试')
        return
      })
  },
  methods: {
    beforeAvatarUpload(file) {
      try {
        let observer = {
          next(res) {
            _this.percent = Number(res.total.percent.toFixed(2))
          },
          error(err) {
            console.log(err)
          },
          complete(res) {
            _this.imageUrl = _this.url + res.key
            _this.$emit('getlist', _this.imageUrl)
          },
        }

        let len = file.name.split('.')
        _this.ext = len[len.length - 1]
        let time = new Date().getTime()
        _this.key = 'netclass/vedio/' + _this.id + time //上传到七牛的空间coupon/avatar/
        let key = _this.key + '.' + _this.ext

        let observable = qiniu.upload(
          file,
          key,
          _this.token,
          _this.CoverputExtra,
          _this.config
        )

        observable.subscribe(observer) // 上传开始
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style scoped>
/*.avatar-uploader {
  text-align: center; 
}*/
.el-select {
  width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.uploadSuccess {
  color: #04be02;
}
</style>
